import { useRef, useState } from 'react';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
// import {useHistory} from 'react-router-dom';
import EditAppointment from '../../components/modal/EditAppointment';
import { db } from '../../utils/firebase';
import {
  doc,
  deleteDoc,
} from 'firebase/firestore';

export default function AppointmentMoreMenut({ id, patient, status }) {
  // const history = useHistory();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // const buttonEdit = () => {
  //   history.push('/edituser');
  // }
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, 'Appointment', id));
    alert('Appointment deleted');
  };

  const [patientId] = useState(id);
  const [patientUrd] = useState(patient);

  console.log(patientId);
  console.log(patientUrd);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {/* history.push(`editproduct?product=${id}`); */}
        {status === 'Cancel By Admin' ? "" : <MenuItem sx={{ color: 'text.secondary' }} onClick={handleOpen}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="View"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>}
      </Menu>

      <EditAppointment
        id={patientId}
        patientId={patientUrd}
        open={open}
        handleOpen={handleOpen}
      />
    </>
  );
}
