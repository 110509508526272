import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
//import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {

  apiKey: "AIzaSyD_3g6F-_zl3N7IebQ3nJf1cuYDIvD62OE",
  authDomain: "sample-16907.firebaseapp.com",
  projectId: "sample-16907",
  storageBucket: "sample-16907.appspot.com",
  messagingSenderId: "1031278104453",
  appId: "1:1031278104453:web:086603324bccf45a1dbeec",
  measurementId: "G-4N44R9MKX9"
  
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
