import React from 'react'

import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {  Stack, Link, Container, Typography } from '@mui/material';

import LoginForm from './login/LoginForm';


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
  <Container maxWidth="sm">
    <ContentStyle>
      <Stack sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom style={{fontWeight: 700}}>
          Sign in to Admin
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
      </Stack>
      {/* <AuthSocial /> */}
      <LoginForm />
      
      <Typography
        variant="body2"
        align="center"
        sx={{
          mt: 3,
          display: { sm: 'none' }
        }}
      >
        Don’t have an account?&nbsp;
        <Link variant="subtitle2" component={RouterLink} to="register" underline="hover">
          Get started
        </Link>
      </Typography>
    </ContentStyle>
  </Container>
  );
}
