import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Link,
  Breadcrumbs,
  Grid,
  Paper,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import Chart from "react-apexcharts";

import { useSelector } from "react-redux";
import QueryStatsIcon from "@mui/icons-material/QueryStats";


import bgimage from "../assets/images/bgimage.png"

// mui icons
import MedicationIcon from "@mui/icons-material/Medication";
import MasksIcon from "@mui/icons-material/Masks";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { format } from 'date-fns';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: 20,
  width: "100%",
}));

const style = {
  loop: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '.7s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
  loop2: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
  loop3: {
    height: 17,
    width: 17,
    borderRadius: 10,
    color: "primary",
    animationName: 'blinker',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
    // animation: "spin 2s linear infinite",
    "@keyframes blinker": {
      from: {opacity: 1},
        to: {opacity: 0}
    },
  },
};
export default function Dashboard() {
  const ui = useSelector((state) => state.ui);
  const user = useSelector((state) => state.user);
  const USERLIST = user.patients;
  const [total, setTotal] = useState(0);
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const uniqueIds = [];
  const newUSERLIST = USERLIST.filter(element => {
    const isDuplicate = uniqueIds.includes(element.FullName);
    if (!isDuplicate) {
      uniqueIds.push(element.FullName);
      return true;
    }
    return false;
  });


  useEffect(() => {
    var rows = [0];
    // let pro = 0;
    for (let i = 0; i < user.finances.length; i++) {
      rows = user.finances.map((finance) => finance.Total);
      //   console.log(pro);
      //   rows.push(pro);
    }
    setTotal(rows.reduce(reducer));

    // console.log(urlId);
    // console.log(location.state.quantity);
    // console.log("row",rows.reduce(reducer))
  }, [total, user]);

  const [optionsRadialBar] = useState({
    plotOptions: {
      radialBar: {
        track: {
          opacity: 0.5,
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return user.users.length;
            },
          },
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: ui.isDarkMode === true ? "black" : "white",
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },

    stroke: {
      lineCap: "round",
    },
    labels: ["Admin", "Patient"],
  });

  const [optionsArea] = useState({
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "Month",
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      labels: {
        style: {
          colors: ui.isDarkMode === true ? "gray" : "gray",
        },
      },
    },

    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "top",
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: ui.isDarkMode === true ? "black" : "white",
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },

    grid: {
      borderColor: "#90A4AE",
      strokeDashArray: 10,

      yaxis: {
        lines: {
          show: false,
        },

        labels: {
          style: {
            colors: ui.isDarkMode === true ? "gray" : "gray",
          },
        },
      },
    },

    colors: ["#5DDC9A", "#E91E63"],
    tooltip: {
      theme: ui.isDarkMode === true ? "light" : "dark",
    },
  });

  const [seriesRadialBar] = useState([
  
    user.users.filter((user) => user.UserType === "Admin").length,
    user.users.filter((user) => user.UserType === "Patient").length,
  ]);
  const [seriesArea] = useState([
    {
      name: "Total Appointment",
      data: [user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "01/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "02/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "03/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "04/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "05/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "06/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "07/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "08/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "09/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "10/2022").length, 
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "11/2022").length,
      user.appointments.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "12/2022").length],
    },
    {
      name: "Total Patient",
      data: [newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "01/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "02/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "03/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "04/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "05/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "06/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "07/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "08/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "09/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "10/2022").length, 
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "11/2022").length,
      newUSERLIST.filter((patient) =>  format(new Date((patient.Created.seconds*1000)), 'MM/yyyy') === "12/2022").length],
    },
  ]);
  return (
    <div style={{ backgroundImage:`url(${bgimage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
    <Box sx={{ mx: { lg: 4, xs: 2 }, mt: 12 }}>
      <Breadcrumbs
        sx={{ mb: 2 }}
        separator={
          <Box
            sx={{ width: 4, height: 4, bgcolor: "gray", borderRadius: "50%" }}
          />
        }
      >
        <Link underline="hover" color="inherit" href="/seller">
          <Typography color="text.primary" variant="body2">
            Home
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          Dashboard
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Item>
            <Card sx={{ display: "flex", boxShadow: 0 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography variant="subtitle1">Total Patient</Typography>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{ fontWeight: 700 }}
                  >
                    {
                      newUSERLIST.length
                    }
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                  <IconButton aria-label="play/pause">
                    <QueryStatsIcon color="primary" sx={style.loop} />
                  </IconButton>
                  <Typography sx={{ fontSize: 12 }}>
                    {/* +2.6% than last week */}
                    Live Tracking
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  width: 200,
                }}
              >
                {/* <Chart options={optionsLine} series={series} type="line" /> */}
                <MasksIcon color="primary" sx={{ fontSize: 120 }} />
              </Box>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Item>
            <Card sx={{ display: "flex", boxShadow: 0 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography variant="subtitle1">Total Medicine</Typography>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{ fontWeight: 700 }}
                  >
                    {user.medicines.length}
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                  <IconButton aria-label="play/pause">
                    <QueryStatsIcon color="primary" sx={style.loop2} />
                  </IconButton>
                  <Typography sx={{ fontSize: 12 }}>
                    {/* +2.6% than last week */}
                    Live Tracking
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  width: 200,
                }}
              >
                {/* <Chart options={optionsLine} series={series} type="line" /> */}
                <MedicationIcon color="primary" sx={{ fontSize: 120 }} />
              </Box>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Item>
            <Card sx={{ display: "flex", boxShadow: 0 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography variant="subtitle1">Total Admin</Typography>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{ fontWeight: 700 }}
                  >
                    {
                      user.users.filter((user) => user.UserType === "Admin")
                        .length
                    }
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                   <IconButton aria-label="play/pause">
                    <QueryStatsIcon color="primary" sx={style.loop3} />
                  </IconButton>
                  <Typography sx={{ fontSize: 12 }}>
                    {/* +2.6% than last week */}
                    Live Tracking
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  width: 200,
                }}
              >
                {/* <Chart options={optionsLine} series={series} type="line" /> */}
                <AdminPanelSettingsIcon
                  color="primary"
                  sx={{ fontSize: 120 }}
                />
              </Box>
            </Card>
          </Item>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Item>
            <Typography
              color="textPrimary"
              sx={{ textAlign: "left", ml: 1, fontWeight: 600, my: 1 }}
            >
              All Users
            </Typography>
            <Chart
              options={optionsRadialBar}
              series={seriesRadialBar}
              type="radialBar"
              height={395}
            />
          </Item>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Item>
            <Typography
              color="textPrimary"
              sx={{ textAlign: "left", ml: 1, fontWeight: 600, my: 1 }}
            >
              Monthly Appointment {"&"} Patients
            </Typography>
            <Chart
              options={optionsArea}
              series={seriesArea}
              type="area"
              height={350}
            />
          </Item>
        </Grid>


      </Grid>
    </Box>
    </div>
  );
}
