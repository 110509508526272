import React from "react";


// Import Styling Page
import style from "../styles/Appointment";


// Import Material UIs
import {
  Box,
  Typography,
  Button,
  TextField,

} from "@mui/material";




import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function ForgotPassword() {
  

  const [payload, setPayload] = React.useState({
    email: "",
  });

  const handleChange = (prop) => (e) => {
    setPayload({ ...payload, [prop]: e.target.value });
  };

  const buttonSend = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, payload.email)
      .then(() => {
        // Password reset email sent!
        // ..
       setPayload({ ...payload, email: "" });
       alert("Password Reset Link has been sent to your email address");
 
      })
      .catch((error) => {
        //const errorCode = error.code;
        const errorMessage = error.code;
        // alert(errorMessage);
        alert(errorMessage);
      
      });
  };

  return (
    <div>
      {/* Main Container */}
      <Box sx={style.mainContainer}>
        {/* Banner */}
        <Box sx={style.banner}></Box>

        {/* Promotional Container - Table Container */}
        <Box sx={style.promotionalSpaceContainer}>
          {/* title */}
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>Forgot Password</Typography>
            <Typography sx={style.promotionalTitleSub}>
              Enter the email address associated with your account{" "}
              <br /> and we'll send you a link to reset your password.
            </Typography>
            <Box sx={{display:"flex", flexDirection:"column", mt: 5, mb: 10,  width: "40%", mx:"auto"} }>
              <TextField
                label="Email"
                value={payload.email}
                onChange={handleChange("email")}
              />
              <Button variant="contained" color="primary" sx={{my: 2,width: "40%", mx:"auto"}} onClick={buttonSend}>
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default ForgotPassword;
