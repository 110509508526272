import { filter } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import {
  Box,
  Link,
  Breadcrumbs,
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReactToPrint from 'react-to-print';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

// components
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  PatientMoreMenu,
} from '../../sections/user';
//
// import USERLIST from '../../_mocks_/user';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

//date fns
import { format } from 'date-fns';
const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'patientName', label: 'Patient Name', alignRight: false },
  { id: 'Created', label: 'Created', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function List(props) {
  const user = useSelector((state) => state.user);
  const USERLIST = user.patients;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('FullName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { history } = props;
  const componentRef = useRef();

  const uniqueIds = [];
  const newUSERLIST = USERLIST.filter(element => {
    const isDuplicate = uniqueIds.includes(element.FullName);
    if (!isDuplicate) {
      uniqueIds.push(element.FullName);
      return true;
    }
    return false;
  });



  console.log(newUSERLIST);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newUSERLIST.map((n) => n.FullName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newUSERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    newUSERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Container sx={{ mt: 12, mb: 5 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 9" direction="column" alignItems="center">
              <Typography variant="h4" gutterBottom>
                Patient List
              </Typography>
              <Breadcrumbs
                sx={{ ml: 0.5 }}
                separator={
                  <Box
                    sx={{
                      width: 4,
                      height: 4,
                      bgcolor: 'gray',
                      borderRadius: '50%',
                      mx: 1,
                    }}
                  />
                }
              >
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  to="/listpatient"
                >
                  <Typography color="text.primary" variant="body2">
                    Patient
                  </Typography>
                </Link>
                <Typography color="gray" variant="body2">
                  List
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              gridColumn="span 3"
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
            >
              <Box gridColumn="span 9">
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/createpatient"
                  startIcon={<AddIcon />}
                >
                  New Patient
                </Button>
              </Box>
              <Box gridColumn="span 3">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      startIcon={<LocalPrintshopOutlinedIcon />}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Box>
            </Box>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer ref={componentRef} sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={newUSERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      UserName,
                      Created,
                      FullName,
                      Category,
                      ResidentAddress,
                      HouseNumber,
                    } = row;
                    const isItemSelected = selected.indexOf(UserName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={() => history.push(`/patientrecord/${id}`)
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selected.includes(FullName)}
                            onChange={(event) => handleClick(event, FullName)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar alt={UserName} src={Image} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {FullName}
                        </TableCell>
                        <TableCell align="left">
                          {format(
                            new Date(Created.seconds * 1000),
                            'MM/dd/yyyy'
                          )}{' '}
                        </TableCell>
                        {/* <TableCell align="right">
                          {Category !== 'Record' && (
                            <PatientMoreMenu id={id} category={Category} />
                          )}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={newUSERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Container >
  );
}
