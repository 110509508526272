import React, { useState } from "react";
import style from "../../styles/CreateUser";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import {
  Container,
  Stack,
  Box,
  Link,
  Typography,
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import LoadingButton from "@mui/lab/LoadingButton";

import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
// icon
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import DeleteIcon from "@mui/icons-material/Delete";

// dropzone
import { useDropzone } from "react-dropzone";

//backend
import { db, storage } from "../../utils/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  arrayUnion
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  mt: 20,
};

const thumb = {
  display: "inline-flex",
  border: "1px dashed gray",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  borderRadius: 100,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: 100,
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const imgEmpty = {
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  width: "30%",
  height: "100%",
};
const thumbInnerEmpty = {
  display: "block",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: 50,
};

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 200,
//   height: 100,
//   boxShadow: 24,
//   p: 4,
// };

export default function Create() {
  const const_term = 1024;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avatarError, setAvatarError] = useState(false);
  // const date = new Date();

  //dropzone

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: 3100000,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // setFieldValue("avatar", "gago");
    },
  });

  //snapbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const remove = (file) => () => {
    console.log("removeFile...");
    getRootProps.onDrop.acceptedFiles.splice(
      getRootProps.onDrop.acceptedFiles.indexOf(file),
      1
    );
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="hahah" />
        <DeleteIcon onClick={remove} />
      </div>
    </div>
  ));

  const thumbsEmpty = (
    <div style={thumb}>
      <div style={thumbInnerEmpty}>
        <AddAPhotoRoundedIcon style={imgEmpty} />
        {/* <Typography fontSize={10} color="textPrimary">Upload photo</Typography> */}
      </div>
    </div>
  );

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Box sx={{ my:2, border: 1, borderRadius: 2, p: 2, color: "red" }}>
      <Typography key={file.path} sx={{ fontSize: 12, fontWeight: 700 }}>
        {file.path} - {(file.size / const_term ** 2).toFixed(3)} Mb
      </Typography>
      {errors.map((e) => (
        <Typography key={e.code} sx={{ fontSize: 12 }}>
          {" "}
          {e.message}
        </Typography>
      ))}
    </Box>
  ));

  //yup and formik

  const LoginSchema = Yup.object().shape({
    genericName: Yup.string().required("Generic Name is required"),
    brandName: Yup.string().required("Brand Name is required"),
    milligram: Yup.string().required("Milligram is required"),
    quantity: Yup.string().required("Quantity is required"),
  });

  const formik = useFormik({
    initialValues: {
      genericName: "",
      brandName: "",
      milligram: "",
      quantity: "",

      // fullName: "",
      // phoneNumber: "",
      // email: "",
      // password: "",
      // confirmPassword: "",
      // // avatar: "",
      // role: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async() => {
      console.log(formik.values.email);

   


              if (files.length > 0) {
                setLoading(true);
                setAvatarError(false);
                // await setDoc(doc(db, "Medicines", userCredential.user.uid), {
                //   UserUid: userCredential.user.uid,
                //   UserEmail: formik.values.email,
                //   ProfilePicName: files[0].name,
                //   UserName: formik.values.fullName,
                //   UserType: formik.values.role,
                //   ContactNumber: Number(formik.values.phoneNumber),
                //   Created: serverTimestamp()
                // });
                const docRef = await addDoc(collection(db, "Medicines"), {
                  GenericName: formik.values.genericName,
                  BrandName: formik.values.brandName,
                  Milligram: formik.values.milligram,
                  Quantity: formik.values.quantity,
                  Image : "",
                  ImageName: "",
                  Created: serverTimestamp()
                });

                await Promise.all(
                  files.map((image) => {
                   const imageRef = ref(storage, `Medicines/${docRef.id}/${image.path}`);
                    uploadBytes(imageRef, image, "data_url").then(async () => {
                      const downloadURL = await getDownloadURL(imageRef);
                      await updateDoc(doc(db, "Medicines", docRef.id), {
                        Image: arrayUnion(downloadURL),
                        ImageName: image.path,
                      });
                    }); 
                    return true;
                  })
                );
                  resetForm({
                    values: {
                      genericName: "",
                      brandName: "",
                      milligram: "",
                      quantity: "",
                    }
                  })
                setSnackbarOpen(true);
                setFiles([]);
                setLoading(false);
                setSubmitting(false);
                  
              } else {
                setAvatarError(true);
                setSubmitting(false);
              }

      
      
  

      console.log(avatarError);
    },
  });

  const {
    errors,
    touched,
    // values,
    resetForm,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    // setFieldValue,
    setSubmitting,
    // getFieldMeta,
  } = formik;

  return (
    <Container sx={{ mt: 12, mb: 5 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Add Medicine Successfully
        </Alert>
      </Snackbar>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Box direction="column" alignItems="center">
            <Typography variant="h4" gutterBottom>
            Add Medicine
            </Typography>
            <Breadcrumbs
              sx={{ ml: 0.5 }}
              separator={
                <Box
                  sx={{
                    width: 4,
                    height: 4,
                    bgcolor: "gray",
                    borderRadius: "50%",
                    mx: 1,
                  }}
                />
              }
            >
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/listmedicine"
              >
                <Typography color="text.primary" variant="body2">
                  Medicine
                </Typography>
              </Link>
              <Typography color="gray" variant="body2">
                Create
              </Typography>
            </Breadcrumbs>
          </Box>
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Item>
                    <Box sx={{ p: 2 }}>
                      <Box {...getRootProps({ className: "dropzone" })}>
                        <input
                          // {...getFieldMeta("avatar")}
                          {...getInputProps()}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Box style={thumbsContainer}>
                            <Box sx={{ m: 1, position: "relative" }}>
                              {thumbs.length !== 0 ? thumbs : thumbsEmpty}

                              {loading && (
                                <CircularProgress
                                  size={110}
                                  sx={{
                                    color: "primary",
                                    position: "absolute",
                                    top: -6,
                                    left: -6,
                                    zIndex: 1,
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                          <Typography sx={style.imageTypography}>
                            Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                          </Typography>
                          {avatarError === true ? (
                            <Typography variant="caption" color="error">
                              Avatar is required
                              {/* {touched.email && errors.avatar}{" "} */}
                            </Typography>
                          ) : (
                            <Typography />
                          )}
                        </Box>
                      </Box>
                      {fileRejectionItems}
                    </Box>
                  </Item>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Item>
                    <Box
                      display="grid"
                      // gridTemplateColumns="repeat(12, 1fr)"
                      sx={style.boxGrid}
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        <TextField
                          fullWidth
                          type="name"
                          label="Brand Name"
                          {...getFieldProps("brandName")}
                          error={Boolean(touched.brandName && errors.brandName)}
                          helperText={touched.brandName && errors.brandName}
                        />
                      </Box>
                      <Box gridColumn="span 6">
                        <TextField
                          fullWidth
                          label="Generic Name"
                          {...getFieldProps("genericName")}
                          error={Boolean(
                            touched.genericName && errors.genericName
                          )}
                          helperText={touched.genericName && errors.genericName}
                        />
                      </Box>
                      <Box gridColumn="span 6">
                        <TextField
                          fullWidth
                          type="number"
                          label="Milligram"
                          {...getFieldProps("milligram")}
                          error={Boolean(
                            touched.milligram && errors.milligram
                          )}
                          helperText={touched.milligram && errors.milligram}
                        />
                      </Box>

                      <Box gridColumn="span 6">
                        <TextField
                          fullWidth
                          type="number"
                          label="Quantity"
                          {...getFieldProps("quantity")}
                          error={Boolean(
                            touched.quantity && errors.quantity
                          )}
                          helperText={touched.quantity && errors.quantity}
                        />
                      </Box>
                 
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        my: 2,
                      }}
                    >
                      <LoadingButton
                        style={{ textTransform: "Capitalize", borderRadius: 8 }}
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                       
                      >
                        Create Medicine
                      </LoadingButton>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Container>
    </Container>
  );
}
